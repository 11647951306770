<template>
  <div>
    <div class="container">
      <div class="flex flex-row items-center mb-6" style="margin-top: 80px;">
        <div class="flex flex-row items-center w-1/2">
          <div class="border-red-600 border-r-4 h-10 w-1 mr-2"></div>
          <div class="sm:text-lg md:text-2xl lg:text-3xl font-bold">
            ข่าวสารและกิจกรรม
          </div>
        </div>
      </div>

      <div class="flex flex-wrap w-full mb-8">
        <router-link to="/news/1" class="w-full sm:w-full lg:w-2/3 md:w-full">
          <cardNews
            :imageBackground="`${imageOne}`"
            title="ประกาศผลการพิจารณาโครงการกองทุนพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม"
            content="ประกาศผลการพิจารณาโครงการ / กิจกรรมที่ได้รับการสนับสนุน จากกองทุนพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม ประจำปีงบประมาณ พ.ศ. ๒๕๖๓ ครั้งที่ ๒ ประเภทคำขอที่มีวงเงินไม่เกิน / เกิน ๑๐ ล้าน"
            containerHeight="500"
            containerMaxHeight="500"
            classEdit="lg:mr-2"
            conditionImage="sm:h-1/3 md:h-1/2 lg:h-1/2"
          />
        </router-link>

        <router-link to="/news/1" class="w-full sm:w-full lg:w-1/3 md:w-1/2 sm:w-1/2" v-for="(item, index) in 16" :key="index" >
          <cardNews
            :imageBackground="`${imageTwo}`"
            title="คณะกรรมการบริหารกองทุนพัฒนาดิจิทัลเพื่อเศรษฐกิจ และสังคม"
            content="ประชุมพิจารณาอนุมัติโครงการตามประกาศเปิดรับข้อเสนอ โครงการประจำปีงบประมาณ พ.ศ.2563..."
            containerHeight="500"
            containerMaxHeight="500"
            conditionImage="sm:h-1/2 md:h-1/2 lg:h-1/2"
          />
        </router-link>
        
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import cardNews from "@/components/news/cardNews";
import image1 from '@/assets/mog/card1.png';
import image2 from '@/assets/mog/card2.svg';

export default {
  components: {
    cardNews,
  },
  setup() {
    let imageOne = ref(image1);
    let imageTwo = ref(image2);
    return {imageOne, imageTwo};
  }
};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}
</style>